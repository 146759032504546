import KAVA_LOGO_URL from '../assets/images/kava.svg'
import { ChainId } from '@uniswap/sdk-core'

export enum SupportedNetwork {
  KAVA,
}

export type NetworkInfo = {
  chainId: ChainId
  id: SupportedNetwork
  route: string
  name: string
  imageURL: string
  bgColor: string
  primaryColor: string
  secondaryColor: string
}

export const KavaNetworkInfo: NetworkInfo = {
  chainId: ChainId.KAVA,
  id: SupportedNetwork.KAVA,
  route: 'kava',
  name: 'Kava',
  bgColor: '#0052ff',
  primaryColor: '#0052ff',
  secondaryColor: '#0052ff',
  imageURL: KAVA_LOGO_URL,
}

export const SUPPORTED_NETWORK_VERSIONS: NetworkInfo[] = [KavaNetworkInfo]
